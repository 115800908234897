<template>
  <div class="banner text-white">
    <b-container class="py-4">
      <img :src="logo" alt="logo" width="250px" />
      <h2 class="my-3">Hoge energiefactuur?</h2>
      <h1 class="fs--md--60 fw--700 mb-5">
        Isoleer je gevel en hou <br />
        <span class="text-$yellow">20% meer warmte</span> in huis <br />
        _________
      </h1>
      <b-col sm="12" md="6">
        <p>
          Een warme thuis zonder je zorgen te moeten maken over de
          energiefactuur? Cralux is expert in isolatie voor gevel, ramen en dak.
          Wij komen ter plaatse, analyseren je situatie en gaan dan over tot het
          opleveren van topkwaliteit!
        </p>
        <div class="banner__arrow d-none d-md-block">
          <img :src="arrow" width="280px" />
        </div>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import logo from "@/assets/v2/logo.png";
import arrow from "@/assets/v2/hero_arrow.png";
export default {
  name: "Banner",
  data() {
    return {
      logo,
      arrow,
    };
  },
};
</script>

<style lang="scss" scoped>
$yellow: rgb(255, 237, 0);

.banner {
  background-image: url(../../assets/v2/banner_bg.jpg);
  width: 100%;
  height: 750px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  & h1 span {
    color: $yellow;
  }
  & p {
    font-family: "Montserrat", sans-serif;
  }
  &__arrow {
    text-align: end;
    margin-top: -70px;
    margin-right: -100px;
  }
}
</style>
