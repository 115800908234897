<template>
  <div class="beste py-5">
    <b-container class="text-center">
      <h1 class="fs--md--32">
        De beste isolatie en mooiste afwerking voor je gevel?
      </h1>
      <p class="py-4 mx-auto">
        Cralux staat voor je klaar. Krijg persoonlijk advies en info over alle
        premies en ontvang je gratis prijsofferte en geniet van een warme thuis
        zonder zorgen!
      </p>
      <b-button
        variant="warning"
        href="#form"
        class="p-3 btnWarningBg text-dark px-5 mx-0 mt-0 mt-md-5 mt-lg-0"
        ><span class="fs--19">Ontvang je gratis offerte »</span>
      </b-button>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "DeBeste",
};
</script>

<style lang="scss" scoped>
.beste {
  background: rgba(0, 0, 0, 1);
  font-family: "Montserrat", sans-serif !important;
  color: rgb(255, 255, 255);
  & p {
    max-width: 640px;
  }
}
</style>
