<template>
  <div id="OnzeRealisaties" class="pt-5">
    <b-col md="6" offset-md="3">
      <b-container class="text-center text-white">
        <h2 class="text-muted">Onze realisaties</h2>
        <h3 class="subHeadingText mb-5">Enkel het beste is goed genoeg.</h3>
        <p class="peraText mb-5">
          We begrijpen dat onze gerealiseerde projecten niet enkel gebouwen
          vertegenwoordigen maar ook plannen vormen voor onze toekomstige
          klanten. We gebruiken bekende technieken en technologien om onze
          maximale potentieel te bereiken.
        </p>
      </b-container>
    </b-col>
    <b-container>
      <b-col md="10" offset-md="1">
        <img src="../assets/1.jpg" width="100%" />
      </b-col>
      <div class="row mt-5">
        <b-col md="4" offset-md="1">
          <img src="../assets/2.jpg" width="100%" />
        </b-col>
        <b-col md="6" class="mt-5 mt-md-0">
          <img src="../assets/3.jpg" width="100%" height="84%" />
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "OnzeSection",
};
</script>

<style>
#OnzeRealisaties {
  background: #232323;
}
.subHeadingText {
  font-size: x-large;
}
.peraText {
  font-size: small;
}
</style>
