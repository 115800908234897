<template>
  <div class="fixedBg"></div>
</template>

<script>
export default {
  name: "FixedBg",
};
</script>

<style lang="scss" scoped>
.fixedBg {
  height: 100vh;
  background-image: url(../../assets/v2/fixedBg.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
