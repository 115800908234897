<template>
  <div class="realisaties py-5">
    <b-container class="text-center">
      <h1 class="fs--md--54 fw--700">Onze realisaties</h1>
      <h2 class="fs--md--26 fw--600">Enkel het beste is goed genoeg.</h2>
      <p class="mt-5 d-none d-md-block">
        We begrijpen dat onze gerealiseerde projecten niet enkel gebouwen
        vertegenwoordigen <br />
        maar ook plannen vormen voor onze toekomstige klanten. We gebruiken
        bekende <br />
        technieken en technologien om onze maximale potentieel te bereiken.
      </p>
      <p class="my-5 d-block d-md-none">
        We begrijpen dat onze gerealiseerde projecten niet enkel gebouwen
        vertegenwoordigen maar ook plannen vormen voor onze toekomstige klanten.
        We gebruiken bekende technieken en technologien om onze maximale
        potentieel te bereiken.
      </p>
      <b-row class="mt-4">
        <b-col sm="12" md="6" lg="8">
          <img :src="img1" width="100%" height="100%" />
        </b-col>
        <b-col sm="12" md="6" lg="4" class="d-none d-md-block">
          <img :src="img2" width="100%" class="mb-2" />
          <img :src="img3" width="100%" class="mt-4" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="12" md="6" lg="5">
          <img :src="img4" width="100%" class="mb-4 mb-md-2" />
          <img :src="img5" width="100%" class="mt-4 d-none d-md-block" />
        </b-col>
        <b-col sm="12" md="6" lg="7">
          <img :src="img6" width="100%" height="82%" />
        </b-col>
      </b-row>
      <b-button
        variant="warning"
        href="#form"
        class="p-3 btnWarningBg text-dark px-5 mx-0 mt-0 mt-md-5 mt-lg-0"
        ><span class="fs--19">Ontvang je gratis offerte »</span>
      </b-button>
    </b-container>
  </div>
</template>

<script>
import img1 from "@/assets/v2/img1.jpg";
import img2 from "@/assets/v2/img2.jpg";
import img3 from "@/assets/v2/img3.jpg";
import img4 from "@/assets/v2/img4.png";
import img5 from "@/assets/v2/img5.jpg";
import img6 from "@/assets/v2/img6.jpg";
export default {
  name: "OnzeRealisaties",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
    };
  },
};
</script>

<style lang="scss" scoped>
.realisaties {
  background: rgba(0, 0, 0, 1);
  font-family: "Montserrat", sans-serif !important;
  color: rgb(255, 255, 255);
  & h1 {
    color: rgb(170, 170, 170);
  }
}
</style>
