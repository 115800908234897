<template>
  <b-container class="py-5 troeven">
    <h1 class="fs--md--54 fw--700">Onze troeven</h1>
    <h2 class="fs--md--26 fw--700">Cralux maakt voor jou het verschil.</h2>
    <p class="my-5">
      Eén bedrijf dat je hele huis optimaal isoleert? Dat is de definitie van
      Cralux. Wij zijn expert in thermische isolatie van gevel, ramen én dak.
      Daarnaast maken wij het verschil dankzij ons uitgebreid aanbod, onze
      toegewijde vakmannen en onze onklopbare prijs-kwaliteit!
    </p>
    <b-row class="align-items-center my-5">
      <b-col sm="12" md="6">
        <h2 class="fs--md--26 fw--700">Topmaterialen voor jouw gevel</h2>

        <p>
          We hebben de beste isolatiematerialen alsook de beste afwerking in
          crepi. We gebruiken de crepi met siliconen, deze is volledig
          waterafstotend en weersbestendig. Perfect voor België!
        </p>
        <p class="mb-0">
          Verder gebruiken we de <b>optimale isolatiedikte</b>, in lijn met de
          EU norm voor 2050. Zo moet je binnen 25 jaar niet opnieuw isoleren en
          spaar je een hoop kosten uit!
        </p>
      </b-col>
      <b-col sm="12" md="6">
        <img :src="img" width="100%" />
      </b-col>
    </b-row>
    <b-row class="align-items-center my-5">
      <b-col sm="12" md="6">
        <img :src="img1" width="100%" />
      </b-col>
      <b-col sm="12" md="6">
        <h2 class="fs--md--26 fw--700">Het oog wil ook wat</h2>
        <p>
          Nu zit je gezellig warm binnen maar uiteraard wil je dat je huis er
          vanbuiten ook netjes uitziet. Afwerking met crepi geeft een mooie
          <b>tijdloze uitstraling</b> en je huis ziet er weer uit als nieuw. Je
          kan alle kleuren keizen van de regenboog en doordat we werken met
          silicone, blijft de afwerking mooi proper.
        </p>
      </b-col>
    </b-row>
    <b-row class="align-items-center my-5">
      <b-col sm="12" md="6">
        <h2 class="fs--md--26 fw--700">10 jaar garantie</h2>

        <p class="mb-5">
          Omdat we 100% geloven in onze afwerking met crepi, krijg je maar
          liefst 10 jaar garantie!
        </p>
        <b-button
          variant="warning"
          href="#form"
          class="p-3 btnWarningBg text-dark px-5 my-2 mx-0"
          ><span class="fs--19">Ik wil een nieuwe gevel »</span>
        </b-button>
      </b-col>
      <b-col sm="12" md="6">
        <img :src="img2" width="100%" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import img from "@/assets/v2/img8.png";
import img1 from "@/assets/v2/img9.jpg";
import img2 from "@/assets/v2/img10.jpg";

export default {
  name: "OnzeTroeven",
  data() {
    return {
      img,
      img1,
      img2,
    };
  },
};
</script>

<style lang="scss" scoped>
.troeven {
  font-family: "Montserrat", sans-serif !important;
  & h1 {
    color: rgb(213, 213, 213);
  }
  & h2 {
    color: rgb(51, 51, 51);
  }
}
</style>
