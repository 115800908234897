<template>
  <div id="OnsAanbod" class="my-5">
    <b-container class="my-5">
      <b-row>
        <b-col md="4" offset-md="2" class="mt-4">
          <div class="text-center">
            <img
              src="../assets/OnsAanbod.jpg"
              width="85%"
              height="335px"
              class="leftImg"
            />
            <b-button
              href="#form"
              pill
              variant="warning"
              class="p-3 px-5 px-md-3 btnBg text-dark mx-5"
              ><span>Ontvang uw offerte »</span>
            </b-button>
          </div>
        </b-col>
        <b-col md="5">
          <div>
            <h3 class="heading">Ons aanbod</h3>
            <p class="text-muted underHeading">
              Onze gevelspecialisten komen de toestand van de gevel ter plaatse
              opmeten en bieden zij u persoonlijk advies over uw gevel:
            </p>
          </div>
          <div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="rightTextHeading">Capaciteit</h5>
                <p class="text-muted underHeading">
                  In ons bedrijf zijn dagelijks 6 bouwadviseurs en 11
                  renovatieploegen op de baan. We hebben dus alle bouwexpertise
                  in huis en zijn niet afhankelijk van andere aannemers voor de
                  uitvoering. Dankzij onze grote voorraad aan bouwmaterialen,
                  kunnen we een optimaal continuïteit garanderen.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="rightTextHeading">Kwaliteit</h5>
                <p class="text-muted underHeading">
                  Onze vakmannen werken enkel met topproducten. Daarnaast hebben
                  we vanuit onze expertise een eigen productielijn voor
                  siliconencrepi ontwikkeld. Omdat we 100% vertrouwen op onze
                  kwaliteit, geniet jij als klant van 10 jaar garantie op
                  structurele gebreken.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="rightTextHeading">Oplevertijd</h5>
                <p class="text-muted underHeading">
                  Dankzij onze grote stock en onze ervaren plaasters, realiseren
                  wij iedere maand minstens 30 projecten en renoveren we zon
                  5000m2 aan gevel- en dakoppervlakte. Heb je een nieuwbouw- of
                  renovatieproject voor Cralux? Wij kunnen snel schakelen in
                  onze planning.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="rightTextHeading">Service</h5>
                <p class="text-muted underHeading">
                  In elke provincie in Belgie heeft Cralux lokale bouwadviseurs
                  die hun tijd nemen voor een goed gesprek en een grondige
                  gevelinspectie. Ook onze bouwteams beschikken over eigen
                  stellingen, professionele producten en de nieuwste machines om
                  de werken uit te voeren. Topservice gegarandeerd!
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "OnsAanbod",
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  .leftImg {
    width: 100%;
  }
}
.btnBg {
  background: yellow !important;
  border: yellow !important;
  font-weight: bold !important;
  margin-top: -30px;
  margin-right: 35px;
}
.btnBg:hover {
  background: rgba(242, 222, 0, 1) !important;
  box-shadow: none !important;
  color: #000 !important;
}
.heading {
  color: #009972;
}
.rightTextHeading {
  font-weight: 400;
  font-size: 19px;
}
.underHeading {
  font-size: 12px;
}
</style>
