<template>
  <b-container class="py-5 welkePremies">
    <b-col md="11" offset-md="1">
      <b-container>
        <h3 class="titleText">
          Welke premies zijn er voor gevelrenovatie?
        </h3></b-container
      >
    </b-col>
    <b-container class="my-5">
      <b-row>
        <b-col md="5" offset-md="1" class="mb-5">
          <div>
            <div class="row mb-4">
              <div class="col-3">
                <img src="../assets/one.png" width="100%" height="83%" />
              </div>
              <div class="col-9">
                <h6>Premie voor gevelisolatie (Fluvius)</h6>
                <p class="text-muted welkePremies__peraText">
                  De netbeheerder Fluvius reikt een premie uit voor
                  buitenmuurisolatie (langs buiten) van € 30 per m². Heb je
                  uitsluitend nachttarief? Dan loopt dit op tot maar liefst € 45
                  per m².
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="row my-4">
              <div class="col-3">
                <img src="../assets/two.png" width="100%" height="83%" />
              </div>
              <div class="col-9">
                <h6>Overkoepelende renovatiepremie</h6>
                <p class="text-muted welkePremies__peraText">
                  De Vlaamse renovatiepremie deelt een gevelrenovatie in onder
                  Categorie 1 (de structurele elementen). Dankzij deze premie
                  wordt 20% of 30% van je uitgaven terugbetaald
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="row my-4">
              <div class="col-3">
                <img src="../assets/three.png" width="100%" height="83%" />
              </div>
              <div class="col-9">
                <h6>Totaalrenovatiebonus</h6>
                <p class="text-muted welkePremies__peraText">
                  Combineer je gevel- of spouwmuurisolatie met 2 andere
                  energiebesparende maatregelen? Dan kom je in aanmerking voor
                  de Totaalrenovatiebonus. Die kan oplopen tot € 4.750.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="row my-4">
              <div class="col-3">
                <img src="../assets/four.png" width="100%" height="83%" />
              </div>
              <div class="col-9">
                <h6>Verlaagd BTW tarief</h6>
                <p class="text-muted welkePremies__peraText">
                  Is je woning ouder dan 10 jaar? Dan betaal je slechts 6% BTW
                  in plaats van de gebruikelijke 21% voor jouw gevelrenovatie.
                  Dat scheelt onmiddellijk een pak op de uiteindelijke factuur!
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="5" class="text-center"
          ><img src="../assets/WelkePremies-right.png" width="80%" />
          <b-button
            href="#form"
            pill
            variant="warning"
            class="p-3 px-5 btnBg text-dark mt-4"
            ><span>Krijg tot 4 premies »</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.titleText {
  color: #009972;
}
.welkePremies {
  &__peraText {
    font-size: 12px;
  }
}
.btnBg :hover {
  background: rgba(242, 222, 0, 1) !important;
  box-shadow: none !important;
  color: #000 !important;
}
</style>
