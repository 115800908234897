<template>
  <div class="fixedBg"></div>
</template>

<script>
export default {
  name: "FixedBg",
};
</script>

<style>
.fixedBg {
  background-image: url(../assets/fixedBg.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 600px;
}
</style>
