<template>
  <div class="renoveren pb-3 pt-2">
    <b-container class="my-5 text-white">
      <b-row>
        <b-col md="4" offset-md="2">
          <h3>Gevel renoveren?</h3>
          <p class="underHeading">
            Cralux staat voor u klaar. Krijg persoonlijk advies en info over
            alle premies en ontvang uw gratis prijsofferte, inclusief onze
            <b> tijdelijke actie!</b>
          </p>
        </b-col>
        <b-col md="3" offset-md="1" class="text-center">
          <b-button
            href="#form"
            pill
            variant="warning"
            class="p-3 px-5 px-md-3 btnBg text-dark mt-5"
            ><span>Krijg uw korting »</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
.renoveren {
  background: #008765;
}
.underHeading {
  font-size: 12px;
}
.btnBg:hover {
  background: rgba(242, 222, 0, 1) !important;
  box-shadow: none !important;
  color: #000 !important;
}
</style>
