<template>
  <div id="OnzeTroeven" class="py-5">
    <b-container class="mt-5">
      <b-row>
        <b-col md="5" offset-md="2">
          <div>
            <h3 class="heading">Onze troeven</h3>
            <p class="text-muted underHeading">
              Bij Cralux wordt elk project gewaardeerd. Van het eerste contact
              tot de oplevering maken wij het verschil dankzij onze toegewijde
              vakmannen en onklopbare prijs-kwaliteit!
            </p>
          </div>
          <div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="leftTextHeading">Uitgebreid aanbod</h5>
                <p class="text-muted underHeading">
                  We voorzien van een groot aanbod van diensten in de bouw. We
                  gebruiken onze jarenlange ervaring en technische kennis om
                  <b>uw droomhuis te bouwen. </b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="leftTextHeading">Toegewijde vakmannen</h5>
                <p class="text-muted underHeading">
                  Aan onze toegewijde werknemers bieden we een open werkomgeving
                  die creativiteit, tolerantie, professionele ontwikkeling en
                  zekerheid aanmoedigt. Op die manier leveren wij
                  <b>topkwaliteit bij elk project.</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 text-center">
                <img src="../assets/circle.png" width="55%" />
              </div>
              <div class="col-10 p-0">
                <h5 class="leftTextHeading">
                  <u>Actie</u>: 50% korting op blauwsteen gevelplint
                </h5>
                <p class="text-muted underHeading">
                  Cralux staat niet alleen voor kwaliteit: wij denken ook aan je
                  budget. Daarom geven wij nu de
                  <b>gevelplint in kwalitatieve blauwsteen aan halve prijs!</b>
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="5" align-self="center">
          <div class="text-center">
            <img src="../assets/OnzeTroeven.jpg" width="70%" />
            <b-button
              href="#form"
              pill
              variant="warning"
              class="p-2 px-4 btnBg mx-4"
              ><span>Ontvang uw offerte »</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
.heading {
  color: #009972;
}
.leftTextHeading {
  font-weight: 400;
  font-size: 19px;
}
.underHeading {
  font-size: 12px;
}
.btnBg {
  background: yellow !important;
  border: yellow !important;
  font-weight: bold !important;
  margin-top: -30px;
  margin-right: 35px;
}
.btnBg:hover {
  background: rgba(242, 222, 0, 1);
  box-shadow: none;
  color: #000;
}
</style>
