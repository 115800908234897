<template>
  <div id="form">
    <h4 class="pt-3 text-center">Ontvang uw gratis offerte</h4>
    <div class="text-center">
      <img src="../assets/formArrow.gif" width="25%" />
    </div>

    <p>Waarover wilt u meer informatie?</p>

    <b-form @submit.prevent="onSubmit">
      <b-form-group id="checkbox" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="temp_answers.answers"
          id="checkbox"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="3256"> &nbsp;Crepi</b-form-checkbox>
          <b-form-checkbox value="3253"> &nbsp;Gevelisolatie</b-form-checkbox>
        </b-form-checkbox-group>
        <div>
          <b-form-input
            class="my-2"
            :state="fNameValidation"
            v-model="form.firstname"
            placeholder="Voornaam"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="fNameValidation">
            Alleen letters zijn toegestaan.
          </b-form-invalid-feedback>
        </div>
        <div>
          <b-form-input
            class="my-2"
            :state="lNameValidation"
            v-model="form.lastname"
            placeholder="Achternaam"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="lNameValidation">
            Alleen letters zijn toegestaan.
          </b-form-invalid-feedback>
        </div>
        <div>
          <b-form-input
            class="my-2"
            :state="emailValidation"
            v-model="form.email"
            type="email"
            placeholder="E-mailadres"
            required
          ></b-form-input>
        </div>
        <div>
          <b-form-input
            class="my-2"
            maxlength="10"
            :state="phoneValidation"
            v-model="form.phone_number"
            type="tel"
            placeholder="Telefoonnummer"
            required
          ></b-form-input>
          <b-form-invalid-feedback :state="phoneValidation">
            Telefoonnummer moet 10 getallen bevatten.
          </b-form-invalid-feedback>
        </div>
        <div class="row">
          <div class="col-8">
            <b-form-input
              class="my-2"
              :state="streetValidation"
              v-model="form.street"
              type="text"
              placeholder="Straat"
              required
            ></b-form-input>
          </div>
          <div class="col-4">
            <b-form-input
              class="my-2"
              :state="houseValidation"
              v-model="form.house_number"
              type="text"
              placeholder="Huisnummer"
              required
            ></b-form-input>
          </div>
        </div>

        <b-form-input
          class="my-2"
          :state="zipValidation"
          v-model="form.zip"
          type="text"
          maxLength="6"
          placeholder="Postcode"
          required
        ></b-form-input>

        <b-form-checkbox-group
          v-model="temp_answers.answers"
          id="checkbox"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="3262" required class="my-2 checkboxText">
            &nbsp;Ja, ik wens de nieuwsbrief te ontvangen</b-form-checkbox
          >
        </b-form-checkbox-group>
        <b-button
          type="submit"
          block
          pill
          variant="warning"
          class="p-3 btnBg text-dark w-100 my-2"
          ><b-spinner v-if="getLoader" /><span v-else>Aanvraag versturen</span>
        </b-button>
        <p class="formEndText">
          Alle gegevens zijn vertrouwelijk en worden behandeld in
          overeenstemming met de Europese wetgeving inzake de bescherming van de
          persoonlijke levenssfeer. Lees meer in ons
          <a href="#form" class="text-white" v-b-modal.PrivacyPolicy>
            privacybeleid.</a
          >
        </p>
        <p class="formEndText">
          Door vrijblijvend dit formulier in te vullen gaat u akkoord met de
          <a href="#form" class="text-white" v-b-modal.TermsConditions>
            algemene voorwaarden.</a
          >
        </p>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Form",
  data() {
    return {
      validationCheck: true,
      emailErr: true,
      temp_answers: {
        answers: [],
      },
      formErr: "",
      form: {
        language: "nl_BE",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        street: "",
        house_number: "",
        zip: "",
        site_custom_url: "https://cralux.verbeterthuis.be/",
        site_custom_name: "cralux",
        answers: [],
        site_subid: "",
        publisher_id: "",
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    async onSubmit() {
      let regex = /^([A-Za-z0-9 ]*)$/;
      this.formErr = this.form.street.match(regex) ? false : true;
      let flag = !this.formErr;
      if (flag) {
        let arr = this.temp_answers.answers;

        let uncheckValue = arr.includes("3262");
        if (!uncheckValue) {
          arr.push("3265");
        }

        let checkOne = arr.includes("3256");
        let checkTwo = arr.includes("3253");
        let compare = !checkOne && !checkTwo;
        if (compare) {
          arr.push("3259");
        }

        let resp = await this.postLead({
          ...this.form,
          answers: [...this.temp_answers.answers, 3250],
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.$router.push("bedankt");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader"]),
    fNameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    streetValidation() {
      if (this.form.street == "") {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z ]*$/;

      if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (this.form.house_number == "") {
        this.validationCheck = false;
        return null;
      }

      let houseRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;
      if (this.form.house_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.house_number.match(houseRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.btnBg {
  background: yellow !important;
  border: yellow !important;
  font-weight: bold !important;
}
.checkboxText {
  font-size: small;
}
.formEndText {
  font-size: 8px;
}
</style>
