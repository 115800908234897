<template>
  <div class="banner">
    <b-container>
      <b-col md="5" offset-md="6" class="pt-4"
        ><h1 class="banner__heading">Gevelrenovatie? Cralux!</h1>
      </b-col>
    </b-container>
    <b-container>
      <b-row>
        <b-col md="5" offset-md="1">
          <div class="mt-0 mt-md-4">
            <img
              src="../assets/onBannerTag.png"
              width="100%"
              class="mt-0 mt-md-3"
            />
            <div class="my-3 mb-5">
              <h3 class="banner__leftText">
                <i class="fa-solid fa-check"></i> Duurzaamheid
              </h3>
              <div class="container">
                <p class="text-muted banner__leftTextPera">
                  Meer geld en energie besparen? Dat start bij de plaatsing van
                  thermische isolatie op je buitenmuur in combinatie met
                  hoogwaardige crepi. Onze aanpak: analyseren, adviseren en
                  realiseren.
                </p>
              </div>
              <h3 class="banner__leftText">
                <i class="fa-solid fa-check"></i> Maatwerk
              </h3>
              <div class="container">
                <p class="banner__leftTextPera text-muted">
                  Onze bouwexperts komen langs voor een gratis gevelinspectie en
                  stellen een oplossing op maat voor die naadloos inspeelt op
                  jouw noden en de hoogste isolatienormen. Wij berekenen ter
                  plaatse een offerte en maken je meteen wegwijs in de
                  verschillende isolatie- en renovatiepremies!
                </p>
              </div>
              <h3 class="banner__leftText">
                <i class="fa-solid fa-check"></i> Vakmanschap
              </h3>
              <div class="container">
                <p class="banner__leftTextPera text-muted">
                  Ervaring is troef bij Cralux! Onze werkploegen plaatsen elke
                  maand meer dan 5000m2 crepi in heel Vlaanderen en Brussel. Wij
                  werken snel, netjes en met de puntjes op de afwerking. Onze
                  bonus? In combinatie met het plaatsen van crepi nemen we ook
                  alle kleine klusjes voor een volledige afwerking op!
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="5">
          <h5 class="banner__textLarge">
            Geniet nu van
            <span class="banner__subTextBg text-white">50% korting</span> op
            gevelplint in blauwe steen en granietkorrel!
          </h5>
          <b-container>
            <div class="banner__formBg my-5">
              <div class="container text-white">
                <Form />
              </div>
            </div>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Form from "./Form.vue";
export default {
  name: "Banner",
  components: { Form },
};
</script>

<style lang="scss" scoped>
.banner {
  background: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 49%;
  @media (max-width: 992px) {
    background-size: 100% 34%;
  }
  @media (max-width: 767.98px) {
    background-size: 100% 24%;
  }
  &__heading {
    font-size: 41px;
    font-weight: 700;
    padding-right: 10px;
  }
  &__subTextBg {
    background: #009972;
  }
  &__textLarge {
    font-size: large;
  }
  &__leftText {
    color: #009972;
    font-size: 21px;
    font-family: "Montserrat", sans-serif;
  }
  &__leftTextPera {
    font-size: 11.5px;
  }
  &__formBg {
    background: #009972;
  }
}
</style>
