<template>
  <div id="form" class="text-white formBg">
    <div v-if="!submited">
      <h4 class="pt-3 text-center fs--22 fw--600">Ontvang uw gratis offerte</h4>
      <div class="text-center">
        <img src="@/assets/formArrow.gif" width="25%" />
      </div>

      <p class="fs--15 fw--600">Waarover wil je meer informatie?</p>

      <b-form @submit.prevent="onSubmit">
        <b-form-group id="checkbox" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="temp_answers.answers"
            id="checkbox"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-checkbox value="3256"> &nbsp;Crepi</b-form-checkbox>
            <b-form-checkbox value="3253"> &nbsp;Gevelisolatie</b-form-checkbox>
          </b-form-checkbox-group>
          <!-- firstName -->
          <div>
            <input
              type="checkbox"
              class="hidden-checkbox"
              v-model="bootCheck"
            />
            <b-form-input
              class="my-3"
              :state="fNameValidation"
              v-model="form.firstname"
              placeholder="Voornaam"
              required
            ></b-form-input>
          </div>
          <!-- lastname -->
          <div>
            <b-form-input
              class="my-3"
              :state="lNameValidation"
              @input="handleFieldInput('firstname')"
              v-model="form.lastname"
              placeholder="Achternaam"
              required
            ></b-form-input>
          </div>
          <!-- email -->
          <b-form-input
            class="my-3"
            :state="emailValidation"
            @input="handleFieldInput('email')"
            v-model="form.email"
            type="email"
            placeholder="E-mailadres"
            required
          ></b-form-input>
          <!-- phone -->
          <div>
            <b-form-input
              class="my-3"
              maxlength="10"
              :state="phoneValidation"
              v-model="form.phone_number"
              @input="handleFieldInput('phone_number')"
              type="tel"
              placeholder="Telefoonnummer"
              required
            ></b-form-input>
          </div>
          <!-- street & house -->
          <div class="row">
            <div class="col-8">
              <b-form-input
                :state="streetValidation"
                @input="handleFieldInput('street')"
                v-model="form.street"
                type="text"
                placeholder="Straat"
                required
              ></b-form-input>
            </div>
            <div class="col-4">
              <b-form-input
                :state="houseValidation"
                v-model="form.house_number"
                @input="handleFieldInput('house_number')"
                type="text"
                placeholder="Huisnummer"
                required
              ></b-form-input>
            </div>
          </div>
          <!-- zip -->
          <b-form-input
            class="my-3"
            :state="zipValidation"
            @input="handleFieldInput('zip')"
            v-model="form.zip"
            type="text"
            maxLength="6"
            placeholder="Postcode"
            required
          ></b-form-input>

          <b-form-checkbox-group
            v-model="temp_answers.answers"
            id="checkbox"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-checkbox value="3262" required class="my-2 checkboxText">
              &nbsp;Ja, ik wens de nieuwsbrief te ontvangen</b-form-checkbox
            >
          </b-form-checkbox-group>
          <b-container>
            <b-button
              type="submit"
              block
              variant="warning"
              class="p-3 btnWarningBg text-dark w-100 my-2 mx-0"
              ><b-spinner v-if="getLoader" /><span v-else class="fs--20"
                >Aanvraag versturen</span
              >
            </b-button>
          </b-container>
          <p class="formEndText">
            Alle gegevens zijn vertrouwelijk en worden behandeld in
            overeenstemming met de Europese wetgeving inzake de bescherming van
            de persoonlijke levenssfeer. Lees meer in ons
            <a href="#form" class="text-white" v-b-modal.PrivacyPolicy>
              privacybeleid.</a
            >
          </p>
          <p class="formEndText">
            Door vrijblijvend dit formulier in te vullen gaat u akkoord met de
            <a href="#form" class="text-white" v-b-modal.TermsConditions>
              algemene voorwaarden.</a
            >
          </p>
        </b-form-group>
      </b-form>
    </div>
    <div
      v-else
      class="py-5 d-flex flex-column text-center align-items-center justify-content-center"
    >
      <h6>Bedankt voor uw aanvraag</h6>
      <p>
        Cralux zal zo snel mogelijk contact opnemen voor een bezoek ter plaatse
        en een vrijblijvende offerte, inclusief groepskorting.
      </p>
      <h6>
        Meer groepsaankopen ontdekken? Check
        <a href="https://www.samengoedkoper.be/" target="_blank"
          >SamenGoedkoper.be!</a
        >
      </h6>
      <img
        v-if="profId != null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.verbeterthuis.be/m/5389/a8cb305a191c/?event=6304&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Form",
  data() {
    return {
      bootCheck: false,
      submited: false,
      validationCheck: true,
      emailErr: true,
      temp_answers: {
        answers: [],
      },
      formErr: "",
      form: {
        language: "nl_BE",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        street: "",
        house_number: "",
        zip: "",
        site_custom_url: "https://cralux.verbeterthuis.be/gevelrenovatie2",
        site_custom_name: "gevelrenovatie_version2",
        answers: [],
        site_subid: "",
        publisher_id: "",
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      let regex = /^([A-Za-z0-9 ]*)$/;
      this.formErr = this.form.street.match(regex) ? false : true;
      let flag = !this.formErr;
      if (flag) {
        let arr = this.temp_answers.answers;

        let uncheckValue = arr.includes("3262");
        if (!uncheckValue) {
          arr.push("3265");
        }

        let checkOne = arr.includes("3256");
        let checkTwo = arr.includes("3253");
        let compare = !checkOne && !checkTwo;
        if (compare) {
          arr.push("3259");
        }

        let resp = await this.postLead({
          ...this.form,
          bootCheck: this.bootCheck,
          answers: [...this.temp_answers.answers, 3250],
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.submited = true;
        }
      }
    },
    confirmEmail() {
      this.$bvModal
        .msgBoxOk(
          "Je e-mail adres lijkt een spelfout te bevatten. Gelieve deze te controleren voordat u verder gaat.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Oké",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          this.emailErr = value;
        });
    },
  },
  computed: {
    ...mapGetters(["getLoader", "profId", "getErrorResponses"]),
    fNameValidation() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;

      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    streetValidation() {
      if (this.form.street == "" && !this.getErrorResponses.street) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z ]*$/;
      if (this.getErrorResponses.street == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (
        this.form.house_number == "" &&
        !this.getErrorResponses.house_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;
      if (this.getErrorResponses.house_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.house_number.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "" && !this.getErrorResponses.zip) {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.getErrorResponses.zip == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#form {
  padding: 15px;
  background: rgba(0, 0, 0, 1);
  margin-top: -400px;
  margin-left: -40px;
  @media only screen and (max-width: 767.98px) {
    margin-top: -145px;
    margin-bottom: 25px;
    margin-left: 0px;
  }
}
.btnBg {
  background: yellow !important;
  border: yellow !important;
  font-weight: bold !important;
}
.checkboxText {
  font-size: small;
}
.formEndText {
  font-size: 8px;
}
</style>
