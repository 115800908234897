<template>
  <b-col md="10" offset-md="1">
    <div>
      <b-navbar toggleable="lg" type="light" variant="white">
        <b-navbar-brand href="#"
          ><img src="../assets/logo.png" width="130px"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="mx-auto">
            <b-nav-item href="#OnsAanbod" class="mx-3">Ons aanbod</b-nav-item>
            <b-nav-item href="#OnzeRealisaties" class="mx-3"
              >Onze realisaties</b-nav-item
            >
            <b-nav-item href="#OnzeTroeven" class="mx-3"
              >Onze troeven</b-nav-item
            >
            <b-nav-item href="#OnsTeam" class="mx-3">Ons team</b-nav-item>
            <span class="navBtn py-2 px-3">
              <a href="#form" class="navBtnText">50% korting</a>
            </span>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "Nav",
};
</script>

<style>
.navBtn {
  background: #009972;
}
.navBtnText {
  color: white;
  text-decoration: none;
}
</style>
