<template>
  <b-container class="text-center py-5">
    <small
      >Copyright 2022 SamenGoedkoper.be i.s.m. Cralux - Alle rechten
      voorbehouden.</small
    >
    <br />
    <small>
      <span v-b-modal.algemene>Algemene voorwaarden</span> |
      <span v-b-modal.gegevensbescherming>Gegevensbescherming</span> |
      <span v-b-modal.privacybeleid>Privacybeleid</span></small
    >

    <!-- modal -->
    <!-- Algemene -->
    <b-modal
      id="algemene"
      hide-header
      ok-only
      ok-variant="warning"
      ok-title="oké"
      size="lg"
      scrollable
    >
      <h2>Algemene voorwaarden</h2>

      <p>
        Lees aandachtig deze Algemene Voorwaarden. Deze Algemene Voorwaarden
        bepalen de contractmodaliteiten van de toegang tot de Site en het
        gebruik van de diensten die worden aangeboden door Conversion Impact BV
        waarvan de maatschappelijke zetel zich bevindt op de Koolmijnenkaai 62
        te B-1080 Brussel (hierna "Conversion Impact"). U kunt de Algemene
        Voorwaarden lezen en afdrukken alvorens u toegang krijgt tot onze
        diensten. De Algemene Voorwaarden zijn permanent op onze website
        toegankelijk. Tijdens het invullen van een Offerteaanvraagformulier als
        Gebruiker, of bij ondertekening van een Bestelbon als Leverancier, of
        bij ondertekening van een ander type contract als Partner, zal men u
        vragen de kennisname van de Algemene Voorwaarden te bevestigen. In het
        Offerteaanvraagformulier gebeurt dit door “Ik aanvaard de algemene
        voorwaarden” aan te vinken. In de Bestelbon, of in een ander type
        Conversion Impact contract, gebeurt dit door het document waarin
        expliciet verwezen wordt naar de Algemene Voorwaarden te ondertekenen.
        Vanaf dat ogenblik wordt u geacht kennis te hebben genomen van de
        Algemene Voorwaarden en de bepalingen ervan te aanvaarden. De Algemene
        Voorwaarden zullen op elk moment op de Conversion Impact Site
        beschikbaar zijn, of kunnen steeds telefonisch opgevraagd worden.
      </p>

      <h3>1. DEFINITIES</h3>

      <p>
        Met het oog op deze Algemene Voorwaarden krijgen de volgende bepalingen
        de strikte definitie zoals bepaald werd in dit artikel. Het enkelvoud
        omvat het meervoud en vice versa.<br />
        (1) “Algemene Voorwaarden”: Deze algemene voorwaarden zijn tegenstelbaar
        aan (i) de Gebruikers bij het uitsturen van hun Offerteaanvraag en hun
        inschrijving op de Site, (ii) de Leveranciers bij het ondertekenen van
        de Bestelbon, of tijdens hun eventuele proefperiode, en (iii) de
        Partners waarmee Conversion Impact een contractuele verbintenis aangaat.
        <br />
        (2) “Bestelbon”: De getekende bevestiging van de Leverancier ter
        onderschrijving van een Leverancierscontract. Deze bevestiging kan ofwel
        digitaal ofwel op papier gebeuren. <br />
        (3) “Contract”: Omvat zowel het Gebruikerscontract, het
        Leverancierscontract, alsook elk ander type contract afgesloten met een
        Partner. Het Contract omvat de Algemene Voorwaarden. <br />
        (4) ”Credit Packs”: Het bedrag dat een Leverancier aan Conversion Impact
        online betaalt om toegang te krijgen tot een beperkt aantal
        Offerteaanvragen. <br />
        (5) ”Diensten”: De diensten die door de Leveranciers geleverd worden en
        aan de Gebruikers aangeboden worden naar aanleiding van een
        Offerteaanvraag. <br />
        (6) “Domiciliëring”, “Automatische Incasso” of “SEPA Direct Debit“:
        Betalingsmethode waarbij de Leverancier of Partner de toestemming geeft
        aan Conversion Impact om de Conversion Impact facturen automatisch te
        incasseren bij de bank van de Leverancier of Partner. <br />
        (7) “Extranet”: Afgesloten gedeelte van de Site, dat enkel toegankelijk
        is door een Lid mits gebruik van een persoonlijk Paswoord en
        Gebruikersnaam. <br />
        (8) “Geaffilieerde Websites”: Websites, webpagina's, Apps of andere
        kanalen waar de Marktplaats Diensten van Conversion Impact staan of aan
        verbonden zijn. Deze kanalen kunnen eigendom van Conversion Impact zijn
        of van Partners. <br />
        (9) “Gebruiker”: Een particulier, bedrijf, instelling of organisatie die
        een of meer Product(en) of Dienst(en) wenst aan te kopen en die in
        Zakenrelatie wenst te treden met een Leverancier via de Site om dit
        (deze) Product(en) of Dienst(en) te verkrijgen. (10)
        “Gebruikerscontract”: De verbintenis die gesloten wordt tussen
        Conversion Impact en de Gebruiker bij het uitsturen van de
        Offerteaanvraag en de hieraan verbonden inschrijving van de Gebruiker.
        Het Contract omvat de Algemene Voorwaarden en het
        Offerteaanvraagformulier. <br />
        (11) “Ledengegevens”: Elke informatie die door de Gebruikers in het
        Offerteaanvraagformulier geleverd worden. Dat omvat elke informatie die
        door de Leverancier gegeven wordt en bevestigd wordt in de Bestelbon,
        alsook elke informatie die door de Partner bevestigd wordt in zijn
        contract, evenals elke andere informatie die door de Leden tijdens de
        uitvoering van het Contract verstrekt worden. <br />
        (12) “Gebruikersnaam”: De codenaam die de Leden krijgen en die zal
        aangewend worden in het kader van zijn activiteiten op de Site. <br />
        (13) “Inschrijvingsformulier”: Vragenlijst die een Lid invult met zijn
        Ledengegevens. Voor de Gebruikers is deze vragenlijst verwerkt in het
        Offerteaanvraagformulier. Voor de Leveranciers en Partners zijn er
        aparte vragenlijsten op de Site. <br />
        (14) “Leverancier”: Lid dat één of meer Diensten of Producten wenst te
        verkopen door te antwoorden op de Offerteaanvraag die door een Gebruiker
        via de Site verstuurd werd. Om effectief klant te worden en
        Offerteaanvragen te kunnen ontvangen, moet de Leverancier een Bestelbon
        tekenen met Conversion Impact. Een Leverancier kan eventueel ook zelf
        Offerteaanvragen uitsturen als Gebruiker. <br />
        (15) “Leverancierscontract”: Een contract tussen Leverancier en
        Conversion Impact dat tot stand komt in de Bestelbon, om Leverancier de
        toegang te verstrekken tot de Offerteaanvragen en de Site. Het Contract
        omvat de Algemene Voorwaarden en de Bestelbon.<br />
        (16) “Leveranciersprofiel”: Publiek profiel van de Leverancier op de
        Site. De Leverancier heeft toegang tot zijn profiel in het Extranet. In
        dit profiel komen ook de reviews of beoordelingen door de Gebruikers van
        de Leveranciers. <br />
        (17) “Levering”: Het moment waarop een Offerteaanvraag aan de
        Leverancier geleverd wordt. <br />
        (18) “Lid”: Categorie die de Gebruikers, Leveranciers en Partners omvat.
        <br />
        (19) “Marktplaats Diensten”: Alle diensten aangeboden door Conversion
        Impact met betrekking tot het in relatie brengen van partijen, de Lead
        generatie, alsook alle aanverwante marketing diensten. <br />
        (20) “Offerte”: De verkoopofferte van Product(en) of Dienst(en) door een
        Leverancier geformuleerd naar aanleiding van een Offerteaanvraag. <br />
        (21) “Offerteaanvraag” of “Lead”: Procedure om Product(en) en/of
        Dienst(en) aan te vragen door een Gebruiker, of eventueel een
        Leverancier of Partner, nadat hij een Offerteaanvraagformulier op de
        Site ingevuld heeft. <br />
        (22) “Offerteaanvraagformulier”: De webpagina's in te vullen door de
        Gebruiker waarin hij zijn Offerteaanvraag en zijn Ledengegevens invoert
        om een Zakenrelatie aan te knopen met Leveranciers. Het versturen van
        een Offerteaanvraagformulier maakt het Gebruikscontract tegenstelbaar
        aan de Gebruiker. <br />
        (23) “Paswoord” of “Wachtwoord”: De toegangscode van het Lid om toegang
        te krijgen tot de diensten die op de Site worden voorgesteld. Het
        Paswoord wordt door de Conversion Impact toegekend. Het Lid heeft
        evenwel de mogelijkheid zijn Paswoord te wijzigen. <br />
        (24) “Partner”: Bedrijf of organisatie dat een commercieel contract
        afsluit met Conversion Impact, afgezien van de Leverancierscontracten.
        <br />
        (25) “Producten”: De Producten die door de Leveranciers worden geleverd
        en naar aanleiding van een Offerteaanvraag aan de Gebruikers worden
        voorgesteld. <br />
        (26) “Site”: De internetsites van Conversion Impact waarvan het adres
        https://www.conversion impact.be en https://www.samengoedkoper.be is,
        alsook het geheel van Geaffilieerde Websites. De Site omvat niet alleen
        de webpagina's maar eveneens het geheel van diensten die door Conversion
        Impact voorgesteld worden. <br />
        (27) “Transactie”: Elk schriftelijk of mondeling definitief contract dat
        gesloten wordt tussen een Leverancier en een Gebruiker, en waarvan de
        afsluiting voortkomt uit een Zakenrelatie. <br />
        (28) “Vergoeding”: Het totaal van de bedragen die door Conversion Impact
        geïnd worden in ruil voor de toegang tot de Site en het Extranet alsook
        het gebruik ervan door de Leveranciers, alsook het totaal van de
        bedragen die door Conversion Impact geïnd worden in ruil voor advies,
        marketing, ontwikkeling of andere diensten die Conversion Impact levert
        aan Leveranciers of aan Partners. <br />
        (29) “Zakenrelatie”: Periode vanaf het ogenblik waarop de Gebruiker en
        de Leverancier met elkaar in contact komen naar aanleiding van een
        Offerteaanvraag.
      </p>

      <h3>2. VOORWERP VAN HET CONTRACT</h3>

      <p>
        Als tegenprestatie van de Vergoeding geeft Conversion Impact aan de
        Leverancier toegang tot de Site en Extranet met het oog op het ontvangen
        van Offerteaanvragen met als doel Zakenrelaties tot stand te brengen
        tussen Gebruikers en Leveranciers die bij Conversion Impact ingeschreven
        zijn. Als tegenprestatie voor het naleven van de verplichtingen van de
        Gebruiker geeft Conversion Impact aan de Gebruiker toegang tot de Site
        met het oog op het versturen en opvolgen van Offerteaanvragen. Als
        tegenprestatie van de Vergoeding, of andere commerciële afspraak,
        ontvangt of verschaft de Partner andere diensten van/aan Conversion
        Impact.
      </p>

      <h3>3. INSCHRIJVINGEN EN LEDENGEGEVENS</h3>

      <p>
        De Gebruiker schrijft zich in bij het invullen van een Offerteaanvraag.
        Wanneer een Leverancier de Site wil gebruiken moet die zich vooraf
        inschrijven op het Inschrijvingsformulier door zijn Ledengegevens in te
        vullen, of zal Conversion Impact de Leverancier eerst inschrijven en zal
        deze zijn Ledengegevens bevestigen in de Bestelbon. Partners kunnen al
        dan niet toegang krijgen tot de Site na inschrijving door Conversion
        Impact en bevestiging van de Ledengegevens via Bestelbon of ander type
        contract tussen de Partner en Conversion Impact. In geen geval kan een
        Lid een Gebruikersnaam en/of een Paswoord invoeren die hem niet
        toebehoren. Het Lid dient alle redelijke middelen aan te wenden om zijn
        Paswoord geheim te houden en om ze niet door een derde te laten
        gebruiken. Het Lid is verantwoordelijk voor het naleven van de
        vertrouwelijkheid van zijn Paswoord en voor alle handelingen die hun
        gebruik mogelijk maken. Het Lid verbindt zich ertoe Conversion Impact
        onmiddellijk op de hoogte te brengen van elk niet veroorloofd gebruik
        van zijn Gebruikersnaam of van zijn Paswoord of van elke andere inbreuk,
        zelfs potentiële, op de veiligheid van de Site. Conversion Impact is
        gerechtigd de inschrijving te weigeren van elk Lid dat niet voldoet aan
        de door Conversion Impact vereiste voorwaarden. Indien Conversion Impact
        de inschrijving aanvaardt, verleent Conversion Impact toegang tot de
        Site en het Extranet overeenkomstig de Algemene Voorwaarden. Inzake het
        gebruik van de Site verbindt het Lid zich ertoe (a) Ledengegevens te
        verstrekken die correct, actueel, precies en volledig zijn, (b) de
        Ledengegevens bij te werken zodat ze correct, actueel, precies en
        volledig blijven, (c) geen vals gebruik te maken van de identiteit van
        enig ander persoon of eenheid, of een valse naam te gebruiken of een
        naam voor het gebruik waarvan het Lid geen toestemming gekregen heeft.
        Indien één van de door het Lid verstrekte Ledengegevens niet correct,
        actueel, precies of volledig is, behoudt Conversion Impact zich het
        recht voor het Contract onmiddellijk op te zeggen en elk nieuw gebruik
        van de Site te weigeren.
      </p>

      <h3>4. GEBRUIK VAN DE SITE EN VERPLICHTINGEN VAN CONVERSION IMPACT</h3>

      <p>
        Zodra de inschrijving van het Lid door Conversion Impact aanvaard is,
        heeft het Lid toegang tot de Site. Conversion Impact stelt alles in het
        werk om te voorzien in een toegang tot de Site 24 uur op 24 en 7 dagen
        op 7. Gezien de aan internet eigen technische kenmerken kan Conversion
        Impact niet uitsluiten dat een onderbreking van de toegang tot de Site
        wegens onderhoud, een vertraging in de overdracht van de informatie, of
        een defect aan zijn server zich voordoen. In geval van defect of
        onderbreking zal Conversion Impact alles in het werk stellen om dit zo
        snel mogelijk te verhelpen.
      </p>

      <h3>5. VERGOEDING EN BETALING</h3>

      <p>
        Alle Marktplaats Diensten van de Conversion Impact Site zijn gratis voor
        de Gebruiker. De Gebruiker kan dus gratis Offerteaanvragen uitsturen op
        de Conversion Impact Site. Conversion Impact heeft niettemin het recht
        om Offerteaanvragen te weigeren en te verwerpen op de Site. Een
        Leverancier verbindt zich ertoe om, na bevestiging via een Bestelbon, de
        Vergoedingen te betalen. Leverancierscontracten komen tot stand door het
        inzenden van een Bestelbon door de Leverancier aan Conversion Impact, en
        de bevestiging door Conversion Impact bij middel van het verzenden van
        een factuur. Een Offerteaanvraag wordt geacht geleverd te zijn door
        Conversion Impact aan de Leverancier zodra deze Offerteaanvraag in het
        Extranet van de Leverancier verschijnt. De Conversion Impact facturen
        worden in principe enkel betaald via Domiciliëring of Automatische
        Incasso. Uitzonderlijk kan Conversion Impact andere betalingsmethodes
        voor de Vergoeding toestaan, zoals betaling via Credit Packs of via een
        andere methode. De Credit Packs kunnen online aangekocht en betaald
        worden en geven aan de Leverancier de mogelijkheid credits te kopen die
        zij vervolgens kunnen gebruiken om toegang te krijgen tot bepaalde
        Offerteaanvragen. Deze credits zijn maximum één jaar lang geldig. Ter
        compensatie van de muntontwaarding en de verhoging van de activiteiten,
        zullen alle Conversion Impact tarieven jaarlijks met een forfaitair
        percentage van vijf procent verhoogd worden. De Vergoedingen kunnen
        onder geen enkele voorwaarde teruggevraagd worden door, of teruggestort
        worden aan de Leverancier. De Contracten zijn ook niet overdraagbaar
        naar andere Leveranciers, andere Gebruikers of andere Partners. Alle
        facturen die door Conversion Impact uitgegeven worden, zijn onmiddellijk
        betaalbaar per Domiciliëring. Indien expliciet schriftelijk anders
        overeengekomen zullen de facturen niettemin binnen vijftien dagen na
        factuurdatum moeten betaald worden. Indien Conversion Impact
        betalingsherinneringen moet sturen, zal Conversion Impact hiervoor tot
        veertig Euro aanmaningskosten kunnen aanrekenen. De verschuldigde
        bedragen die op hun vervaldag niet betaald zijn, leveren een interest op
        van rechtswege en zonder ingebrekestelling, alleen door het feit van de
        niet-betaling, van één procent per maand vanaf de vervaldag. Voor de
        berekening van de interesten wordt elke begonnen maand als een volledige
        maand beschouwd. Bovendien zal het bedrag van rechtswege en zonder
        ingebrekestelling verhoogd worden met vijftien procent van het
        factuurbedrag, met een minimum van vijftig Euro, ten titel van vast
        overeengekomen schadevergoeding. In geval van niet tijdige betaling van
        de factuur kan Conversion Impact ofwel het Leverancierscontract
        opschorten en de toegang tot het leveranciersgedeelte voor de
        Leverancier afsluiten tot op de dag van betaling, ofwel het
        Leverancierscontract ontbinden ten nadele van de Leverancier. In geval
        van ontbinding van het Leverancierscontract door Conversion Impact
        ingevolge de niet tijdige betaling door de Leverancier zal deze laatste
        van rechtswege en zonder ingebrekestelling het overeengekomen bedrag
        verschuldigd blijven, te verhogen met de verwijlintresten en
        schadevergoeding zoals voorzien in dit artikel. Deze
        betalingsmodaliteiten zijn eveneens van toepassing voor alle Conversion
        Impact facturen met betrekking tot andere diensten van Conversion Impact
        geleverd aan Partners.
      </p>

      <h3>6. VERPLICHTINGEN VAN DE GEBRUIKER</h3>

      <p>
        De Gebruiker verbindt zich ertoe geen Offerteaanvragen uit te sturen die
        misleidend, geringschattend of onjuist zijn, of waarvan hij de
        uitvoering niet kan verzekeren. De Gebruiker verbindt zich ertoe om
        enkel Offerteaanvragen uit te sturen met de bedoeling om een potentiële
        Transactie te realiseren. De Gebruiker verbindt zich ertoe om geen
        Offerteaanvragen uit te sturen met als bedoeling prijzen of aanbiedingen
        van zijn eigen concurrenten op te vragen en/of te vergelijken. Naar
        aanleiding van een Offerteaanvraag heeft de Gebruiker de verplichting
        binnen vijf dagen na een dergelijk verzoek de volgende informatie aan
        Conversion Impact mede te delen: (a) het bestaan of de afwezigheid van
        de Transactie, (b) elke aanvullende informatie die door Conversion
        Impact gevraagd wordt.
      </p>

      <h3>7. VERPLICHTINGEN VAN DE LEVERANCIER</h3>

      <p>
        De Leverancier verbindt zich ertoe om de Gebruiker die hem een
        Offerteaanvraag via de Site stuurde te contacteren binnen de 48 uur.
        Behalve indien expliciet anders overeengekomen tussen Leverancier en
        Gebruiker, zal de Leverancier steeds zijn Offerte gratis doorsturen naar
        de Gebruiker, dus zonder hiervoor kosten aan te rekenen. De Leverancier
        verbindt zich er ook toe geen Offertes te maken die misleidend,
        geringschattend of onjuist zijn, of waarvan hij de uitvoering niet kan
        verzekeren. De Leverancier verbindt zich eveneens ertoe enkel Offertes
        te maken die conform zijn met zijn statutair doel. Naar aanleiding van
        een Offerte heeft de Leverancier de verplichting binnen vijf dagen na
        een dergelijk verzoek aan Conversion Impact de volgende informatie te
        verstrekken: (a) het bestaan of de afwezigheid van Transactie, (b) elke
        aanvullende informatie die door Conversion Impact gevraagd wordt.
      </p>

      <h3>
        8. DEGELIJK GEBRUIK VAN DE CONVERSION IMPACT SITE EN/OF DE MET
        CONVERSION IMPACT GEAFFILIEERDE WEBSITE
      </h3>

      <p>
        Noch de Leden, noch de met Conversion Impact Geaffilieerde Websites,
        mogen de toegestuurde gegevens op geen enkele manier manipuleren of
        gebruiken voor de creatie van publicaties of diensten die op enigerlei
        kunnen concurreren of interfereren met de publicaties of diensten die
        worden geleverd, of die in de toekomst zullen worden door Conversion
        Impact. De toegestuurde gegevens mogen enkel aangewend worden voor
        persoonlijk gebruik of voor gebruik binnen het bedrijf. Zij mogen in
        geen geval worden verkocht of overgedragen worden aan een derde partij,
        behalve mits schriftelijke goedkeuring door Conversion Impact. Het Lid
        en de Geaffilieerde Website verbinden zich ertoe zich te houden aan de
        wettelijke en contractuele bepalingen die op de Site van toepassing zijn
        en stemmen ermee in niet te interfereren in het gebruik door andere
        Leden, al dan niet concurrenten. Het Lid is de enige verantwoordelijke
        voor de informatie die hij meedeelt op de Site. De Geaffilieerd Website
        is de enige verantwoordelijke voor de informatie die hij meedeelt op
        zijn webpagina’s of op enig ander communicatievorm of -kanaal. De Leden
        en de Geaffilieerde Websites verbinden zich ertoe de Conversion Impact
        Marktplaats Diensten niet te gebruiken voor onwettelijke doeleinden of
        doeleinden die onverenigbaar zijn met de doelstelling van de Site. De
        Leden en de Geaffilieerde Websites verbinden zich er ook toe de op de
        Site ingestelde beschermingstechnieken en de toegangscodes niet te
        doorbreken, te kopiëren, te verspreiden of te gebruiken of te trachten
        ze te doorbreken, te kopiëren, te verspreiden of te gebruiken (zoals
        "reverse engineering"). De Leden en de Geaffilieerde Websites, verbinden
        zich eveneens ertoe de toegang tot en het gebruik van de met de Site
        verbonden computerinfrastructuur niet te verstoren, op welke manier ook.
        De Leden en de Geaffilieerde Websites verbinden zich tenslotte ertoe
        geen ongevraagde advertenties of ongevraagde e-mails te sturen of te
        verspreiden, of enig andere vorm van spam te verspreiden of te
        versturen, alsook alle regelgeving inzake GDPR te respecteren.
      </p>

      <h3>9. WIJZIGING VAN DE ALGEMENE VOORWAARDEN VAN DE SITE</h3>

      <p>
        Conversion Impact kan op elk ogenblik de Algemene Voorwaarden wijzigen,
        onder meer om een wettelijke bepaling na te leven. De Leden zullen via
        de Conversion Impact nieuwsbrief of op andere geschikte wijze van de
        ingetreden wijzigingen op de hoogte gebracht worden. De Leden verbinden
        zich ertoe van de wijzigingen kennis te nemen zodra deze wijzigingen aan
        hem medegedeeld worden. Elk gebruik van de Site na de kennisgeving van
        een dergelijke wijziging laat de kennisname van de gewijzigde Algemene
        Voorwaarden veronderstellen. Conversion Impact behoudt zich het recht
        voor de configuratie en de inhoud van de Site op elk ogenblik te
        wijzigen. Voor zover mogelijk dient elke belangrijke wijziging aan de
        Leden te worden medegedeeld.
      </p>

      <h3>10. SCHADEVERGOEDING</h3>

      <p>
        De Leden verbinden zich ertoe aan Conversion Impact, en in voorkomend
        geval de filialen, partners, bedienden en bedrijfsleiders een
        schadevergoeding te verlenen in geval van klacht of rechtsgeding dat
        door welke derde ook ingesteld wordt naar aanleiding van een gebrek dat
        verband houdt met het onwettig of bedrieglijk gebruik van de Site, de
        Ledengegevens, of met elke andere inbreuk op de Algemene Voorwaarden of
        op de rechten van derden. In geval van opzegging naar aanleiding van een
        foutieve niet-naleving is het Lid van rechtswege een bedrag verschuldigd
        voor schade en forfaitaire interesten, dat gelijk is aan vijftien
        percent van het nog verschuldigde bedrag, onverminderd de gerechtelijke
        interesten en de eventueel verschuldigde procedurekosten en
        -schadevergoedingen.
      </p>
      <h3>11. UITSLUITING VAN WAARBORG</h3>

      <p>
        Conversion Impact is een bemiddelaar waarvan de opdracht uitsluitend
        erin bestaat op de Site vermelde Leden in contact en in Zakenrelatie te
        brengen door de procedure van een Offerteaanvraag. Gezien het beperkte
        karakter van de bemiddeling van Conversion Impact verleent deze laatste
        geen enkele waarborg inzake de Producten en Diensten die tijdens de
        Zakenrelatie door de tussenkomst van de Site gekocht of verkocht worden.
        Conversion Impact biedt geen enkele waarborg betreffende de resultaten
        die verkregen kunnen worden door het gebruik van de Site of betreffende
        de nauwkeurigheid of de betrouwbaarheid van elke informatie die op de
        Site beschikbaar is of die door de bemiddelaar van de Site verkregen
        werd. De door Conversion Impact aangeboden diensten worden via internet
        voorgesteld. De Gebruikers aanvaarden de risico's die verbonden zijn aan
        het open karakter van internet. Conversion Impact biedt geen enkele
        waarborg betreffende enig defect dat verband houdt met een indringing in
        het computersysteem of met de aanwezigheid van een virus of van fouten
        die bepaalde functies van de Site kunnen aantasten. Elke overdracht van
        informatie op de Site gebeurt op eigen risico van de Gebruiker. Voor
        elke inhoud en/of elk gegeven dat gedownload wordt of dat door het
        gebruik van de Site verkregen wordt, nemen de Leden alleen alle risico
        op zich. De raadgevingen en informaties die eventueel door Conversion
        Impact aan de Leden gegeven worden in het kader van het gebruik van de
        Site, mogen in geen geval geïnterpreteerd worden als afwijkingen van het
        Contract. De met Conversion Impact geaffilieerde sites, zijnde de sites
        die hun leden en /of verzoekers doorverwijzen naar de Site van
        Conversion Impact, bieden ook geen enkele waarborg voor wat de de
        inhoud, diensten en het functioneren van Conversion Impact betreft en
        zijn dus uitgesloten van enige waarborg. Conversion Impact biedt geen
        enkele waarborg voor wat de inhoud, de diensten en het functioneren
        betreft van de sites naar waar Conversion Impact haar Leden en bezoekers
        doorverwijst.
      </p>

      <h3>12. AANSPRAKELIJKHEID</h3>

      <p>
        Conversion Impact en de met Conversion Impact Geaffilieerde Websites
        zijn vrijgesteld van elke aansprakelijkheid inzake de uitvoering en de
        interpretatie van de Transacties die tijdens de Zakenrelatie gesloten
        worden, die uitsluitend behoren tot de aansprakelijkheid van de
        betrokken Leden. Conversion Impact en de met Conversion Impact
        Geaffilieerde Websites kunnen niet aansprakelijk gesteld worden voor de
        inhoud van enige informatie die door de Leden verstuurd wordt, met name
        betreffende het misbruik, de misleiding, de geringschatting, de laster,
        de inbreuk op het privéleven, of de onwettigheid, of die het voorwerp
        zou kunnen zijn van een betwisting, van welke aard ook, afkomstig van
        Leden of van derden. Het Lid is als enige verantwoordelijk voor de
        informatie die vervat is in de Offerteaanvragen, Offertes,
        Ledengegevens, Leveranciersprofiel, alsook elke andere informatie die
        aan Conversion Impact wordt doorgegeven en die bestemd is om op de Site
        te staan. Conversion Impact en de met Conversion Impact Geaffilieerde
        Websites kunnen niet aansprakelijk gesteld worden voor enige
        rechtstreekse of onrechtstreekse schade (zoals onder andere de derving
        van inkomens, van zaken, van contracten, van gepland sparen of van
        winst), die voortkomt uit het gebruik van de Site, de onderbreking van
        de toegang tot de Site of de inhoud van de Offerteaanvragen of Offertes.
        De met Conversion Impact Geaffilieerde Websites, zijnde de sites die hun
        leden of bezoekers doorverwijzen naar de Site van Conversion Impact,
        zijn vrijgesteld van elke aansprakelijkheid voor wat de diensten en het
        functioneren van Conversion Impact betreft. Conversion Impact is
        vrijgesteld van elke aansprakelijkheid voor het doorverwijzen van
        Conversion Impact Leden en bezoekers naar andere sites noch voor de
        inhoud, de diensten en het functioneren van de sites naar waar
        Conversion Impact haar Leden en bezoekers doorverwijst. Bij de
        uitvoering van haar taken van adviesverlening, ontwikkeling of andere
        diensten, levert Conversion Impact een inspanningsverbintenis aan haar
        Leden. Haar aansprakelijkheid is in deze gevallen steeds beperkt tot het
        bedrag dat voor deze dienstverlening aan de klant reeds gefactureerd
        werd en door de klant reeds betaald werd.
      </p>

      <h3>13. OPZEGGING</h3>

      <p>
        Onverminderd het recht om schade en interesten te eisen, behoudt
        Conversion Impact zich het recht voor elk Contract dat met een Lid
        gesloten is, zonder voorafgaande ingebrekestelling op te zeggen ingeval
        deze laatste een van de bepalingen van het Contract niet naleeft. In
        geval van opzegging van het Contract zal het Lid zijn toegangsrecht
        hetzij tijdelijk, hetzij definitief geschorst worden. De vervallen
        bedragen blijven verschuldigd en de reeds gestorte bedragen worden in
        geen geval terugbetaald.
      </p>

      <h3>14. INTELLECTUEEL EIGENDOMSRECHT</h3>

      <p>
        Conversion Impact is exclusief houder van de totale intellectuele
        eigendomsrechten, met inbegrip van de rechten sui generis, die met de
        Site verband houden. Bijgevolg verbindt het Lid zich ertoe op geen
        enkele manier en onder geen enkele vorm de elementen die de Site vormen
        geheel of gedeeltelijk te gebruiken, te exploiteren of te ontvreemden,
        met name de teksten, formulieren, namen, letterwoorden, logo's,
        grafische beelden, grafieken, muziek, enz., of enige andere vorm van
        gelijk welk kenmerkend teken. Het Lid machtigt uitdrukkelijk Conversion
        Impact om op zijn site de teksten, formulieren, namen, letterwoorden,
        logo's, grafische beelden, foto’s, grafieken, muziek, enz., of enige
        vorm van gelijk welk kenmerkend teken te reproduceren, die doorgegeven
        worden in het kader van het Contract.
      </p>

      <h3>15. KENNISGEVING AAN DE GEBRUIKER</h3>

      <p>
        Elke boodschap of elke kennisgeving die aan de Leden gestuurd wordt
        betreffende het gebruik van de Site dient te gebeuren hetzij via de
        Site, hetzij via elektronische post, hetzij via gewone post aan het
        adres dat in de Ledengegevens vermeld wordt. Conversion Impact kan niet
        aansprakelijk gesteld worden voor enig nadeel vanwege het feit dat het
        Lid verzuimd heeft de Ledengegevens bij te werken.
      </p>

      <h3>16. OVERMACHT</h3>

      <p>
        Conversion Impact kan niet aansprakelijk gesteld worden voor het niet
        naleven van een of meer bepalingen van het Contract indien deze
        niet-naleving te wijten is aan overmacht of aan een gebeurtenis die
        redelijkerwijze aan de controle van Conversion Impact ontsnapt.
      </p>

      <h3>17. VERWERKING VAN DE LEDENGEGEVENS</h3>

      <p>
        Voor de toepassing van het Contract is de persoon die instaat voor de
        verwerking van de Ledengegevens Conversion Impact, waarvan de zetel zich
        bevindt in de Koolmijnenkaai 62, te B-1080 Brussel. De Ledengegevens die
        door het Lid naar Conversion Impact gestuurd worden, kunnen door
        Conversion Impact aangewend worden met het oog op de directe marketing
        en om het Leveranciersprofiel op te stellen. Het Lid kan zich verzetten
        tegen het aanwenden van zijn Ledengegevens met het oog op directe
        marketing door het overeenstemmende vakje in het Inschrijvingsformulier
        niet aan te vinken. Elk Lid kan dit ook achteraf doen, door het relevant
        vakje in het Extranet te ontvinken.
      </p>

      <h3>18. GEMEENSCHAPPELIJKE BEPALINGEN</h3>

      <p>
        Het Contract wordt intuitu personae gesloten tussen Conversion Impact en
        het Lid. De rechten en verplichtingen die eruit voortkomen kunnen noch
        geheel, noch gedeeltelijk aan derden worden afgestaan tenzij in geval
        van een fusie of overname. Het feit dat Conversion Impact een recht of
        een bepaling uit het Contract niet uitoefent of toepast, mag niet
        beschouwd worden als een verzaking aan dit recht of aan deze bepaling.
        De beperkingen en uitsluitingen die in de Algemene Voorwaarden zijn
        bepaald, zijn op het Lid van toepassing indien en voor zover zij door de
        Belgische Wetgeving toegestaan zijn. Elke hele of gedeeltelijke bepaling
        van het Contract die ontoepasbaar of onwettelijk verklaard wordt, zal
        uit het Contract verwijderd worden en zal op geen enkele wijze de
        toepasbaarheid van de overige bepalingen van het Contract in het gedrang
        brengen. De Partijen verbinden zich ertoe elke bepaling van het Contract
        die ontoepasbaar of onwettelijk geacht wordt te vervangen door een
        bepaling met hetzelfde economische effect. Elk geschil met betrekking
        tot de uitvoering of de interpretatie van het Contract valt uitsluitend
        onder de bevoegdheid van de Vrederechtbank van het vijfde kanton van
        Brussel of van de rechtbanken van Brussel. Het Belgisch recht is van
        toepassing en de proceduretaal is de taal die gebruikt werd in de
        Ledengegevens van het Lid. Het contract omvat het geheel van de
        contractuele bepalingen tussen Conversion Impact en de Leden betreffende
        de Site en de diensten die erop worden aangeboden, met uitsluiting van
        elk voorafgaand akkoord. De Nederlandstalige en Franstalige versies van
        de Algemene Voorwaarden zijn beide officiële versies.
      </p>
    </b-modal>
    <!-- Gegevensbescherming -->
    <b-modal
      id="gegevensbescherming"
      hide-header
      ok-only
      ok-variant="warning"
      ok-title="oké"
      size="lg"
      scrollable
    >
      <h2>Zo garanderen wij jouw privacy:</h2>

      <h3>Altijd en overal toegang tot jouw gegevens</h3>

      <p>
        Als Conversion-Impact-gebruiker heb je een persoonlijke login. Daarmee
        kan je jouw gegevens bekijken, aanpassen en beheren. Heb je deze account
        niet meer nodig? Dan kan je je account ook volledig verwijderen.
      </p>

      <h3>Volledig zelf onder controle</h3>

      <p>
        In elke stap van onze dienstverlening heb je alles volledig zelf onder
        controle. Je doet zelf een aanvraag. Wij sturen de aanvraag door naar de
        beste partner. Je kan deze aanvraag steeds opvolgen via jouw login
      </p>

      <h3>Data privacy voor leveranciers</h3>

      <p>
        Ben je een leverancier? Dan heb je toegang tot een beveiligde zone op
        onze website. In dit extranet kan je jouw profiel bekijken en alle
        persoonlijke gegevens aanpassen. Je kan je profiel vergrendelen en
        vragen om het te verwijderen.
      </p>

      <h3>Melding bij onregelmatigheden</h3>

      <p>
        Onze infrastructuur en alle gegevens van onze databanken worden gehost
        bij een externe firma die gespecialiseerd is in de bescherming van
        gegevens. Deze firma gebruikt de meest recente technologieën om onze
        gegevens op een veilige manier te beschermen. Indien er ooit een
        beveiligingsprobleem zou opduiken, zullen wij je hiervan onmiddellijk op
        de hoogte brengen.
      </p>

      <h3>GDPR binnen Conversion Impact</h3>

      <p>
        Wij volgen de Europese Privacyregels (GDPR) en hebben een Data
        Protection Manager aangesteld die de implementatie van alle GDPR regels
        binnen ons bedrijf nauwkeurig opvolgt. De Data Protection Manager is het
        aanspreekpunt voor alle vragen of opmerkingen omtrent data en privacy.
        Je kan onze Data Protection Manager bereiken door te mailen naar
        privacy@conversion-impact.be.
      </p>
    </b-modal>
    <!-- Privacybeleid -->
    <b-modal
      id="privacybeleid"
      hide-header
      ok-only
      ok-variant="warning"
      ok-title="oké"
      size="lg"
      scrollable
    >
      <h2 class="my-4">
        Privacy beleid, bescherming van de persoonlijke levenssfeer
      </h2>

      <p>
        Uw gegevens worden geregistreerd in de databanken van Conversion Impact
        bvba en zijn bestemd voor intern gebruik door Conversion impact, haar
        affiliates en partners. In overeenstemming met de wet van 8 december
        1992 tot bescherming van de persoonlijke levenssfeer en de algemene
        verordening Gegevensbescherming van 25 mei 2018, worden de gegevens u
        medegedeeld op uw verzoek, en worden ze, desgevallend, gecorrigeerd of
        volledig verwijderd uit onze databanken. Alle bijkomende informatie kan
        bekomen worden bij de Commissie voor de Persoonlijke Levenssfeer. Al uw
        vragen met betrekking tot de registratie, wijziging of verwijdering van
        uw gegevens kunnen gericht worden aan Conversion Impact bvba, Square
        Sainctelette, 12/6 te B-1000 Brussel, per telefoon op het nummer
        02/504.00.80 of via mail: privacy@conversion-impact.be, al deze
        aanvragen zullen behandeld worden door de functionaris voor
        gegevensbescherming
      </p>

      <h3>Bescherming van de persoonlijke levenssfeer</h3>

      <p>
        Onze doelstelling is uw informatie te beschermen, zowel in het kader van
        Internet als in al onze andere types relaties. Het staat u vrij om deze
        site te bezoeken, informatie in te winnen over onze diensten, kennis te
        nemen van het laatste nieuws of van onze nieuwe diensten, zonder ons
        persoonlijke informatie te moeten verstrekken. Onder uw toestemming
        wordt geen enkele informatie die u aanbelangt, zoals adres, e-mail,
        telefoon- en faxnummer, identificatiegegevens, openbaar gemaakt aan
        derden (buiten Conversion Impact en haar affiliates), gedeeld of
        verkocht. Wij houden alle gegevens betreffende uw beroepsactiviteiten en
        uw transacties up-to-date, volgens onze gewoonlijke standaards van
        strikte vertrouwelijkheid en veiligheid.
      </p>

      <h3>Gebruik van "cookies"</h3>

      <p>
        Wij zullen af en toe cookies gebruiken om u een betere service te
        bieden. Een "cookie" is een klein stuk informatie dat door een website
        opgeslagen wordt in de browser van uw PC (in het algemeen in de map
        C:\WINDOWS\Cookies) : het cookie kan opgehaald worden tijdens een later
        bezoek aan dezelfde site. Het cookie kan niet gelezen worden door een
        andere website dan diegene die het heeft aangemaakt. Wij gebruiken
        cookies voor administratieve doeleinden om, bijvoorbeeld, uw voorkeur te
        registreren voor bepaalde types informatie of uw toegangscode te
        registreren, wat voorkomt dat u bij ieder bezoek aan onze site telkens
        opnieuw dezelfde gegevens moet invoeren via het toetsenbord. De meeste
        cookies werken alleen voor de duur van een enkele sessie, of bezoek.
        Geen enkel ervan bevat informatie waardoor met u contact kan worden
        opgenomen via telefoon, e-mail of "snail mail". U heeft ook de
        mogelijkheid uw browser zodanig te configureren dat hij u verwittigt
        telkens cookies worden aangemaakt of dat hij de aanmaak ervan
        verhindert.
      </p>

      <h3>Beleid inzake persoonlijke levenssfeer en vertrouwelijkheid</h3>

      <p>
        Conversion Impact behoudt zich het recht voor de informatie met
        betrekking tot de berichten, zoals de inhoud, de afkomst of de
        bestemming ervan, te controleren en te onderzoeken, indien het een
        billijk vermoeden heeft dat het geheel of een deel van deze communicatie
        betrekking heeft op illegale of ongeoorloofde activiteiten, of indien
        het daartoe bevel gekregen heeft van de bevoegde autoriteiten. Behalve
        wanneer de controle gebeurt op verzoek van de bevoegde autoriteiten, mag
        de controle van Conversion Impact alleen betrekking hebben op de
        berichten met een openbaar of niet-vertrouwelijk karakter, dat wil
        zeggen de informatie die zich op een webpagina bevindt, de berichten die
        te vinden zijn in de discussiegroepen of de informatiemailings, zonder
        dat deze lijst beperkend is. Conversion Impact mag de maatregelen
        treffen die noodzakelijk zijn om een goed beheer van het computersysteem
        te verzekeren. In dit opzicht kan het toegang verwerven tot iedere
        communicatie die is opgeslagen op zijn computersysteem. De gebruiker
        verbindt zich ertoe het geheime en vertrouwelijke karakter van zijn
        wachtwoord en zijn toegangscode te bewaren. Ieder gebruik van deze
        identificatie-elementen gebeurt op volledige verantwoordelijkheid van de
        gebruiker. In geval van verlies of diefstal of frauduleus gebruik van
        een van deze elementen, is de gebruiker ertoe gehouden Conversion Impact
        daarvan binnen de kortst mogelijke termijn op de hoogte te stellen,
        waarbij deze kennisgeving bevestigd moet worden per aangetekende brief.
        De gebruiker wordt pas ontslaan van zijn verantwoordelijkheid op de
        werkdag die volgt op de dag waarop de aangetekende brief ontvangen werd.
        De gegevens met persoonlijk karakter betreffende de gebruiker worden
        opgenomen in de bestanden van Conversion Impact en worden gebruikt: met
        het oog op het beheer van het cliëntenbestand en het uitvoeren van
        marktstudies; met het oog op de uitgifte, de inning en de controle van
        de facturen; in het kader van de contractuele relatie met de gebruiker
        (bijvoorbeeld, bij briefwisseling of in het geval van geschil); met het
        oog op de verwezenlijking van informatie- of promotiecampagnes over de
        producten en diensten van Conversion Impact. De gegevens met persoonlijk
        karakter die de gebruiker verstuurt, worden geregistreerd in de
        databanken van Conversion Impact. De gebruiker beschikt over een recht
        van inzage,van correctie en volledige verwijdering van deze gegevens.
        Elke gebruiker kan dit recht uitoefenen via zijn of haar persoonlijke
        login op conversion-impact.be. Al uw vragen met betrekking tot de
        registratie, wijziging of verwijdering van uw gegevens kunnen gericht
        worden aan Conversion Impact bvba, Square Sainctelette, 12/6 te B-1000
        Brussel, per telefoon op het nummer 02/504.00.80 of via mail:
        privacy@conversion-impact.be. Voor meer informatie kan de gebruiker zich
        richten tot het openbare register bij de Commissie voor de bescherming
        van de persoonlijke levenssfeer. Op voorwaarde van een gedagtekende en
        ondertekende schriftelijke aanvraag, gericht aan Conversion Impact, kan
        de gebruiker die het bewijs levert van zijn identiteit, van Conversion
        Impact gratis de schriftelijke mededeling bekomen van de gegevens met
        persoonlijk karakter die op hem betrekking hebben, evenals,
        desgevallend, de correctie van de gegevens die onjuist, onvolledig of
        niet relevant zijn. In geval van verandering van de administratieve
        gegevens van de gebruiker, is deze ertoe gehouden Conversion Impact
        daarvan op de hoogte te brengen binnen de maand na de wijziging ervan.
        Bijkomende informatie omtrent de diensten en voorwaarden van Conversion
        Impact, gelieve de FAQ (veel gestelde vragen) en Algemene Voorwaarden te
        lezen.
      </p>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
span {
  cursor: pointer;
  color: #0000ff;
  text-decoration: underline;
}
</style>
