<template>
  <b-container class="py-5 ons">
    <b-row class="formSection-sm">
      <b-col sm="12" md="8">
        <h1 class="fs--md--54 fw--700">Ons aanbod</h1>
        <h4 class="fs--26 fw--700 mb-5">
          De best geïsoleerde gevel in een prachtig jasje
        </h4>
        <b-row class="mb-3" v-for="(data, index) in points" :key="index">
          <b-col cols="2" md="2" lg="1">
            <img :src="mark" width="30px" />
          </b-col>
          <b-col cols="10" class="pl-3">
            <p class="fs--20 fw--600 mb-0 color--primary mb-3">
              {{ data.title }}
            </p>
            <p class="fs--16">
              {{ data.subTitle }}
            </p>
          </b-col>
        </b-row>
        <b-button
          variant="warning"
          href="#form"
          class="p-3 btnWarningBg text-dark my-2 px-5"
          ><span class="fs--20">Vraag je gratis offerte »</span>
        </b-button>
      </b-col>
      <b-col sm="12" md="4"><Form /> </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mark from "@/assets/v2/mark.png";
import Form from "./Form.vue";

export default {
  name: "OnsAanbod",
  components: {
    Form,
  },
  data() {
    return {
      mark,
      points: [
        {
          title: "Gevelanalyse",
          subTitle:
            "1 van onze bouwadviseurs komt ter plaatse om je situatie te analyseren en te bespreken.",
        },
        {
          title: "Persoonlijk advies",
          subTitle:
            "We doen je ter plaatste een voorstel van onze aanpak met onmiddellijk een prijs. We kijken ook welke afwerking van de gevel je verkiest.",
        },
        {
          title: "Razendsnelle oplevering",
          subTitle:
            "We doen er alles aan om de werken zo snel als mogelijk uit te voeren. We houden je op regelmatige tijdstippen op de hoogte wanneer je in de planning staat.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ons {
  font-family: "Montserrat", sans-serif !important;
  & h1 {
    color: rgb(213, 213, 213);
  }
  & .formSection-sm {
    @media only screen and (max-width: 767.98px) {
      flex-direction: column-reverse;
    }
  }
}
</style>
