<template>
  <div class="tip py-5">
    <b-container class="text-center">
      <h1 class="fs--md--54 fw--700">Onze tip</h1>
      <h2 class="fs--md--26 fw--600">Vergeet je VerbouwPremie niet!</h2>
      <b-row class="mt-5">
        <b-col sm="12" md="6">
          <img :src="img" width="100%" />
        </b-col>
        <b-col sm="12" md="6" class="text-start pt-4 pt-md-0">
          <p>
            Een extra reden om je gevel te renoveren: <b>Mijn VerbouwPremie</b>!
            Zowel gevelisolatie als gevelbepleistering met crepi komen in
            aanmerking voor deze premie! Afhankelijk van je inkomen krijg je
            <b>35% tot maar liefst 50% van de investering</b> terug met een
            maximaal premiebedrag van 6.000 euro.
          </p>
          <p class="mb-4">
            Daarnaast helpen we met de aanvraag van gemeentelijke
            <b>vergunningen</b> indien nodig en zorgen we ook voor de
            parkeerborden bij het innemen van een stuk openbare weg.
          </p>
          <b-button
            variant="dark"
            href="#form"
            class="p-3 btnDarkBg text-white px-5 my-2 mx-0"
            ><span class="fs--19">Krijg tot 50% premie »</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import img from "@/assets/v2/img7.jpg";

export default {
  name: "OnzeTip",
  data() {
    return {
      img,
    };
  },
};
</script>
<style lang="scss" scoped>
.tip {
  background: rgba(255, 237, 0, 1);
  font-family: "Montserrat", sans-serif !important;
  & h1 {
    color: rgb(51, 51, 51);
  }
}
</style>
