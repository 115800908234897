<template>
  <div>
    <Banner />
    <OnsAanbod />
    <OnzeRealisaties />
    <OnzeTip />
    <FixedBg class="d-none d-md-block" />
    <OnzeTroeven class="d-none d-md-block" />
    <OnsTeam />
    <DeBeste />
    <Footer />
  </div>
</template>

<script>
import Banner from "@/components/renovatie/Banner.vue";
import OnsAanbod from "@/components/renovatie/OnsAanbod.vue";
import OnzeRealisaties from "@/components/renovatie/OnzeRealisaties.vue";
import OnzeTip from "@/components/renovatie/OnzeTip.vue";
import FixedBg from "@/components/renovatie/FixedBg.vue";
import OnzeTroeven from "@/components/renovatie/OnzeTroeven.vue";
import OnsTeam from "@/components/renovatie/OnsTeam.vue";
import DeBeste from "@/components/renovatie/DeBeste.vue";
import Footer from "@/components/renovatie/Footer.vue";

export default {
  name: "NewHome",
  components: {
    Banner,
    OnsAanbod,
    OnzeRealisaties,
    OnzeTip,
    FixedBg,
    OnzeTroeven,
    OnsTeam,
    DeBeste,
    Footer,
  },
};
</script>
