<template>
  <div id="OnsTeam" class="team py-4">
    <b-container class="my-5 text-center">
      <b-col md="6" offset-md="3">
        <h3 class="heading mb-0">Ons team</h3>
        <h5 class="mb-4">Cralux: uw partner voor elke renovatie</h5>
        <div class="text-muted underHeading">
          <p>
            Cralux is uw partner voor alle renovatiewerken: crepi,
            buitenmuurisolatie, totaalrenovatie, ... Dankzij hun jarenlange
            ervaring in de bouw- en renovatiesector ligt uw project in
            professionele handen.
          </p>
          <p>
            De specialiteit van Cralux? Dat is
            <b>het realiseren van uw droomhuis</b> binnen de grenzen van uw
            budget. Cralux luistert, adviseert en inspireert u van bij het
            eerste gesprek tot het leggen van de laatste steen.
          </p>
          <img src="../assets/team.jpg" width="100%" class="my-3" />
        </div>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
.team {
  background: #f0f0f0;
}
.heading {
  color: #009972;
}
.underHeading {
  font-size: 10px;
}
</style>
