<template>
  <div class="home">
    <Navbar />
    <Banner />
    <FixedBg />
    <OnsAanbod />
    <OnzeSection />
    <WelkePremies />
    <FixedBg />
    <OnzeTroeven />
    <OnsTeam />
    <GevelRenoveren />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Banner from "../components/Banner.vue";
import FixedBg from "../components/FixedBg.vue";
import OnsAanbod from "../components/OnsAanbod.vue";
import OnzeSection from "../components/OnzeSection.vue";
import WelkePremies from "../components/WelkePremies.vue";
import OnzeTroeven from "../components/OnzeTroeven.vue";
import OnsTeam from "../components/OnsTeam.vue";
import GevelRenoveren from "../components/GevelRenoveren.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Banner,
    FixedBg,
    OnsAanbod,
    OnzeSection,
    WelkePremies,
    OnzeTroeven,
    OnsTeam,
    GevelRenoveren,
    Footer,
  },

  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView")
    );
    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" src="https://www.facebook.com/tr?id=969816563708997&ev=PageView &noscript=1"/></noscript>';
  },
};
</script>
