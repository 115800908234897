<template>
  <div class="footer py-4 text-center">
    <b-container>
      <p>
        Copyright 2022 SamenGoedkoper.be i.s.m. Cralux - Alle rechten
        voorbehouden.
      </p>
      <div>
        <p>
          <a href="#footer" v-b-modal.TermsConditions>Algemene voorwaarden</a> |
          <a href="#footer" v-b-modal.DataProtection>Gegevensbescherming</a> |
          <a href="#footer" v-b-modal.PrivacyPolicy>Privacybeleid</a>
        </p>
      </div>
    </b-container>

    <!-- modals -->
    <div>
      <b-modal
        id="TermsConditions"
        centered
        title="Terms And Conditions"
        ok-only
        size="lg"
        ok-variant="warning"
      >
        <iframe
          src="https://www.conversion-impact.be/algemene-voorwaarden.html"
          width="100%"
          height="380px"
        >
        </iframe>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="DataProtection"
        centered
        title="Data Protection"
        ok-only
        size="lg"
        ok-variant="warning"
      >
        <iframe
          src="https://www.conversion-impact.be/gdpr.html"
          width="100%"
          height="380px"
        >
        </iframe>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="PrivacyPolicy"
        centered
        title="Privacy Policy"
        ok-only
        size="lg"
        ok-variant="warning"
      >
        <iframe
          src="https://www.conversion-impact.be/privacy-policy.html"
          width="100%"
          height="380px"
        >
        </iframe>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  font-size: 11px;
}
</style>
