<template>
  <b-container class="text-center teamSection py-5">
    <h1 class="fs--md--54 fw--700">Ons team</h1>
    <h2 class="fs--md--26 fw--700">Cralux: jouw partner voor elke renovatie</h2>
    <p class="d-none d-md-block mt-5">
      Cralux is jouw partner voor je gevel-, ramen en dakrenovatie.
      <b
        >60 vakmannen <br />
        en 20 bouwadviseurs</b
      >
      staan voor u klaar. Stuk voor stuk experten in externe <br />
      thermische isolatie, zowel in advies als in uitvoering.
    </p>
    <p class="d-block d-md-none mt-5">
      Cralux is jouw partner voor je gevel-, ramen en dakrenovatie.
      <b>60 vakmannen en 20 bouwadviseurs</b> staan voor u klaar. Stuk voor stuk
      experten in externe thermische isolatie, zowel in advies als in
      uitvoering.
    </p>
    <p>Samen maken wij van uw huis een <b>warme thuis voor morgen</b>.</p>
    <img :src="img" />
  </b-container>
</template>

<script>
import img from "@/assets/v2/img11.jpg";

export default {
  name: "OnsTeam",
  data() {
    return {
      img,
    };
  },
};
</script>

<style lang="scss" scoped>
.teamSection {
  font-family: "Montserrat", sans-serif !important;
  & h1 {
    color: rgb(213, 213, 213);
  }
  & h2 {
    color: rgb(51, 51, 51);
  }
  & img {
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
}
</style>
